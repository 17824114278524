import { useStaticQuery, graphql } from "gatsby"


// takes in a list of slugs
//    format those slugs to match what is in graphql
//    query for all of the articles
//
// returns a list of article objects sorted to match the input slug list
export const filterTransactionsBySlugs = (slugs: Array<any>) => {
  const transactionSlugs = []
  const filteredTransactions = []
  const sortedTransactions = []

  // make a list of article slugs to match against
  slugs.map((transaction) => {
    transactionSlugs.push(`/transactions/${transaction.transactionSlug}/`) // this is the format from the graphql query
  })

  // query for all of the articles
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { dataType: { eq: "transaction" } } }
      ) {
        edges {
          node {
            frontmatter {
              transactionTitle
              loanAmount
              location
              description
              image
              isRendered
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  // get the articles from the queried data
  data.allMarkdownRemark.edges.map((article: any) => {
    if (!transactionSlugs.includes(article.node.fields.slug)) {
      return null
    } else {
      filteredTransactions.push(article)
    }
  })

  // sort the articles to match the given list
  transactionSlugs.map((slug) => {
    filteredTransactions.map((article) =>{
      if (article.node.fields.slug == slug){
        sortedTransactions.push(article)
      }
    })
  })
  return sortedTransactions
}
