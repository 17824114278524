/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { Link } from "gatsby"
// import React from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Button = ({ isExternal, link, text, buttonVariant }) => {
  return (
    <div sx={{ marginTop: "10px", marginBottom: "10px" }}>
      {isExternal ? (
        <a
          sx={{ variant: `buttons.${buttonVariant}` }}
          role="button"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ) : (
        <Link
          sx={{ variant: `buttons.${buttonVariant}` }}
          to={link}
          role="button"
        >
          {text}
        </Link>
      )}
    </div>
  )
}

export default Button

Button.propTypes = {
  isExternal: PropTypes.bool,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string,
}

Button.defaultProps = {
  buttonVariant: "notDefined",
}
