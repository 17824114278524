/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import MarkdownContent from "../MarkdownContent"

// styles
const mainContainer = {
  backgroundColor: "rgba(120,120,120,0.9)",
  color: "white",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingLeft: "20px",
  paddingRight: "20px",
}

const Footnote = ({ text }) => {
  return (
    <div sx={mainContainer}>
      <div sx={{ maxWidth: "850px" }}>
        <MarkdownContent content={text} />
      </div>
    </div>
  )
}
export default Footnote

Footnote.propTypes = {
  text: PropTypes.string.isRequired,
}
