/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

// follows the same structure as the ComponentContainer component but makes the left and right cols lines
const Title = ({ title }) => {
  return (
    <div
      sx={{
        bg: "white",
        paddingTop: "20px",
        width: "100%",
        display: "flex",
        alignItems: "center", // Vertically aligns all child flex items to sit in the middle of the row
      }}
    >
      <div
        sx={{
          bg: "#a8a8a8", // Single pixel line made with a background colour
          flex: ["0 0 10px", null, "1 0 auto"], // Flex rules change based on viewport/breakpoint
          height: "1px", // For a single pixel line
        }}
      />
      <div
        sx={{
          flex: ["1 1 auto", null, "1 0 800px"], // Set to flex auto for mobile and tablet devices, minimum 800px width for desktop
          maxWidth: "800px", // Prevents the center flex item from growing beyond the 800px width
          padding: "0 0 0 10px", // Left padding so the left line doesn't abut against the section header copy
          // Make this flex item into its own flex container with child flex items!
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "left",
          // Added styles below for copy styling, can remove
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        <div
          sx={{
            flex: "0 1 auto",
            color: "primary",
            fontSize: "22px",
          }}
        >
          {title}
        </div>
        <div
          sx={{
            bg: "#a8a8a8",
            flex: "0 0 20px",
            height: "1px",
            marginLeft: "10px",
          }}
        />
      </div>
      {/* SELF CLOSING DIV TO BALANCE THE FLEX CONTAINER */}
      <div
        sx={{
          flex: ["0 0 10px", null, "1 0 auto"], // Must keep to maintain balance in the flex container
        }}
      />
    </div>
  )
}

export default Title

Title.propTypes = {
  title: PropTypes.string.isRequired,
}
