/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */

import { jsx } from "theme-ui"
import { filterArticlesBySlugs } from "../../utilities/filterArticlesBySlugs"

// components
import Button from "../Button"
import ComponentContainer from "../ComponentContainer"

// styles
const text = {
  color: "#4d4d4d",
  fontSize: "13px",
}
const newsSourceText = {
  fontWeight: 700,
  fontSize: "17px",
  marginTop: "10px",
}
const articleTitle = {
  fontSize: "32px",
  lineHeight: 1.25,
  fontWeight: 300,
  marginTop: "10px",
  color: "primary",
}
const articleInfo = {
  fontSize: "14px",
  marginTop: "10px",
  marginBottom: "10px",
}

const FeaturedNewsArticles = ({ slugs }) => {
  const sortedFilteredArticles = filterArticlesBySlugs(slugs)
  return (
    <div>
      {sortedFilteredArticles.map((a, i) => {
        const article = a.node.frontmatter
        return (
          <ComponentContainer backgroundColor="white" key={i}>
            <div
              sx={{
                display: "flex",
                jusifyContent: "flex-start",
              }}
            >
              <div
                sx={{
                  marginTop: "50px",
                  marginBottom: "50px",
                  maxWidth: ["100%", "100%", "400px"],
                }}
              >
                <div sx={newsSourceText}>{article.newsSource}</div>
                <div sx={articleTitle}>{article.articleTitle}</div>
                {article.articleImage && (
                  <div sx={{ maxWidth: "400px" }}>
                    <img
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        maxHeight: "600px",
                      }}
                      src={article.articleImage}
                      alt={article.articleTitle}
                    />
                  </div>
                )}
                
                  {article.partyIdentity && (
                    <div sx={articleInfo}>
                      <span><strong>3rd Party: </strong>{article.partyIdentity}</span>
                    </div>
                  )}
                  {article.dateOfRanking && (
                    <div sx={articleInfo}>
                      <span><strong>Ranked/Rated: </strong>{article.dateOfRanking}</span>
                    </div>
                  )}
                  {article.applicableTimePeriod && (
                    <div sx={articleInfo}>
                      <span><strong>For: </strong>{article.applicableTimePeriod}</span>
                    </div>
                  )}                 
                               
                <div sx={{ display: "flex", flexDirection: "column" }}>
                  {article.articleFile && (
                    <Button
                      buttonVariant="blueButton"
                      isExternal
                      link={article.articleFile}
                      color="lightBlue"
                      textColor="white"
                      text={ article.buttonCopy ? article.buttonCopy : "Download"}
                    />
                  )}
                  {article.articleLink && (
                    <Button
                      buttonVariant="blueButton"
                      isExternal
                      link={article.articleLink}
                      color="lightBlue"
                      textColor="white"
                      text={ article.buttonCopy ? article.buttonCopy : "Read More"}
                    />
                  )}
                </div>
                {article.note && (
                  <div sx={text}>
                    <p>{article.note}</p>
                  </div>
                )}
              </div>
            </div>
          </ComponentContainer>
        )
      })}
    </div>
  )
}

export default FeaturedNewsArticles
