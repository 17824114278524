/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"

import LeadershipMember from "../components/LeadershipMember"

const LeadershipMemberTemplate = ({ data }) => {
  return (
    <Layout>
      <Metadata />
      <BaseStyles>
        <LeadershipMember data={data} />
      </BaseStyles>
    </Layout>
  )
}

export default LeadershipMemberTemplate

LeadershipMemberTemplate.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query LeadershipMemberById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        position
        headshot
        bio
        department
        footnote
        locationPhoneNumberList {
          location
          phoneNumber
          phoneNumberExtension
        }
        blueTransparentBlock
        # relatedNewsArticles {
          # relatedNewsArticles {
            # articleSlug
          # }
        # }
        # relatedTransactions {
        #  relatedTransactions {
        #    transactionSlug
        #  }
        # }
      }
    }
  }
`
