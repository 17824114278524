/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import MarkdownContent from "../MarkdownContent"
import PopupNote from "../note"

// styles
const mainContainer = {
  backgroundColor: "rgba(0,157,210,.85)",
  color: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  paddingBottom: "40px",
  paddingTop: "40px",
  paddingLeft: "10px",
  paddingRight: "10px",
  fontSize: ["23px", "23px", "32px"],
  fontWeight: 300,
  position: "relative",
}

const BlueTransparentBlock = ({ content, isExtraWide, popup, popupCopy }) => {
  return (
    <div>
    <div sx={mainContainer}>
      <div
        sx={{
          width: ["100%", "100%", isExtraWide ? "1280px" : "850px"],
          textAlign: "center",
          margin: "10px",
        }}
      >
        <div
          sx={{
            maxWidth: "1280px",
            fontSize: "32px",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <MarkdownContent style={{ lineHeight: 1.25 }} content={content} />
        </div>
      </div>
    </div>
    { popup && (
          <div>
            <PopupNote popupCopy={popupCopy}/>
          </div>
        )}
    </div>
  )
}
BlueTransparentBlock.propTypes = {
  content: PropTypes.string.isRequired,
  isExtraWide: PropTypes.bool,
  popup: PropTypes.bool,
  popupCopy: PropTypes.string,
}
BlueTransparentBlock.defaultProps = {
  isExtraWide: false,
  popup: false,
}
export default BlueTransparentBlock
