/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
// components
import Button from "./Button"
import BlueTransparentBlock from "./page-blocks/BlueTransparentBlock"

// utilities
import { formatPhoneNumber } from "../utilities/formatPhoneNumber"
import MarkdownContent from "./MarkdownContent"
import Title from "./page-blocks/Title"
import FeaturedTransactions from "./page-blocks/FeaturedTransactions"
import FeaturedNewsArticles from "./page-blocks/FeaturedNewsArticles"
import Footnote from "./page-blocks/Footnote"

// how the layout works:
// desktop mode: left column and right column
// in tablet/mobile: left column on top of right column
// to get the name/position in right spot, there are 2 instances of them that conditionally
//    render depending on deskptop or tablet/mobile
const TeamMember = ({ data }) => {
  const teamMember = data.markdownRemark.frontmatter
  return (
    <div>
      <div sx={mainContainer}>
        <div sx={columnContainer}>
          <div sx={leftColumn}>
            <div
              sx={{
                display: ["none", "none", "inline"],
                visibility: ["hidden", "hidden", "visible"],
              }}
            >
              <Button
                buttonVariant="grayButton"
                link="/leadership"
                text="View entire leadership"
                color="#eee"
                textColor="primary"
                role="button"
              />
            </div>
            <div sx={{ display: ["inline", "inline", "none"] }}>
              <div sx={{ fontSize: "32px", fontWeight: 300 }}>
                {teamMember.name}
              </div>
              <div sx={{ fontWeight: 700, marginBottom: "20px" }}>
                {teamMember.position}
              </div>
            </div>

            <div
              sx={{ display: "flex", flexDirection: ["row", "row", "column"] }}
            >
              <img
                style={{
                  marginTop: "20px",
                  maxWidth: "200px",
                  marginRight: "20px",
                }}
                src={
                  teamMember.headshot
                    ? teamMember.headshot
                    : "/uploads/silhouette-placeholder.jpeg"
                }
                alt={teamMember.name}
              />

              <div sx={{ display: "flex", flexDirection: "column" }}>
                {teamMember.locationPhoneNumberList &&
                  teamMember.locationPhoneNumberList.map((entry, i) => {
                    return (
                      <div
                        key={i}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          sx={{
                            fontSize: "17px",
                            fontWeight: 700,
                            paddingTop: "16px",
                            color: "primary",
                          }}
                        >
                          {entry.location}
                        </div>
                        <div>
                          {formatPhoneNumber(entry.phoneNumber)}{" "}
                          {entry.phoneNumberExtension && (
                            <div>Ext {entry.phoneNumberExtension}</div>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          <div sx={rightColumn}>
            <div sx={{ display: ["none", "none", "inline"] }}>
              <div sx={{ fontSize: "60px", fontWeight: 100 }}>
                {teamMember.name}
              </div>
              <div sx={{ fontWeight: 700, marginBottom: "20px" }}>
                {teamMember.position}
              </div>
            </div>
            <MarkdownContent
              style={{ lineHeight: 1.75, fontSize: "17px" }}
              content={teamMember.bio}
            />
          </div>
        </div>
      </div>
      {teamMember.blueTransparentBlock && (
        <BlueTransparentBlock content={teamMember.blueTransparentBlock} />
      )}
      {teamMember.relatedTransactions > 0 && (
        <div>
          <Title title="Relevant Deals" />
          <FeaturedTransactions
            slugs={teamMember.relatedTransactions.relatedTransactions}
          />
        </div>
      )}
      {/***
      {teamMember.relatedNewsArticles > 0 && (
        <div>
          <div sx={newsArticleSectionTitle}>Related News Articles</div>
          <FeaturedNewsArticles
            slugs={teamMember.relatedNewsArticles.relatedNewsArticles}
          />
        </div>
      )}**/}
      {teamMember.footnote && (
        <Footnote text={teamMember.footnote} />
      )}
    </div>
  )
}

export default TeamMember

TeamMember.propTypes = {
  data: PropTypes.object,
}

// styles
const mainContainer = {
  backgroundColor: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  paddingBottom: "40px",
}
const columnContainer = {
  marginTop: "50px",
  marginBottom: "50px",
  width: "800px",
  display: "flex",
  flexDirection: ["column", "column", "row"],
  marginLeft: "10px",
  marginRight: "10px",
}
const leftColumn = {
  marginRight: [null, null, "40px"],
}
const rightColumn = {
  width: "100%",
  paddingTop: ["20px", "20px", "0px"],
}
const newsArticleSectionTitle = {
  backgroundColor: "rgba(120,120,120,.85)",
  color: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  paddingBottom: "30px",
  paddingTop: "30px",
  fontSize: "32px",
}
