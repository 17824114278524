/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const ComponentContainer = ({ children, backgroundColor, styles }) => {
  return (
    <div
      style={styles}
      sx={{
        backgroundColor: backgroundColor,
        width: "100%",
        display: "flex",
      }}
    >
      <div
        sx={{
          flex: ["0 0 10px", null, "1 0 auto"], // Flex rules change based on viewport/breakpoint
        }}
      />
      <div
        sx={{
          flex: ["0 1 auto", null, "1 0 800px"], // Set to flex auto for mobile and tablet devices, minimum 800px width for desktop
          width: "100%",
          maxWidth: "800px", // Prevents the center flex item from growing beyond the 800px width
          padding: "0 0 0 10px", // Left padding so the left line doesn't abut against the section header copy
          // Make this flex item into its own flex container with child flex items!
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          
        }}
      >
        {children}
      </div>
      {/* SELF CLOSING DIV TO BALANCE THE FLEX CONTAINER */}
      <div
        sx={{
          flex: ["0 0 10px", null, "1 0 auto"], // Must keep to maintain balance in the flex container
        }}
      />
    </div>
  )
}

export default ComponentContainer

ComponentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}
