import { useStaticQuery, graphql } from "gatsby"


// takes in a list of slugs
//    format those slugs to match what is in graphql
//    query for all of the articles
//
// returns a list of article objects sorted to match the input slug list
export const filterArticlesBySlugs = (slugs: Array<any>) => {
  const articleSlugs = []
  const filteredArticles = []
  const sortedArticles = []

  // make a list of article slugs to match against
  slugs.map((article) => {
    articleSlugs.push(`/news-articles/${article.articleSlug}/`) // this is the format from the graphql query
  })

  // query for all of the articles
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { dataType: { eq: "article" } } }
      ) {
        edges {
          node {
            frontmatter {
              articleTitle
              articleFile
              articleImage
              buttonCopy
              articleLink
              newsSource
              newsType
              partyIdentity
              dateOfRanking
              applicableTimePeriod
              note
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  // get the articles from the queried data
  data.allMarkdownRemark.edges.map((article: any) => {
    if (!articleSlugs.includes(article.node.fields.slug)) {
      return null
    } else {
      filteredArticles.push(article)
    }
  })

  // sort the articles to match the given list
  articleSlugs.map((slug) => {
    filteredArticles.map((article) =>{
      if (article.node.fields.slug == slug){
        sortedArticles.push(article)
      }
    })
  })
  return sortedArticles
}
